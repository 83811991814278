import * as React from 'react'
import { graphql } from "gatsby"
import ItemView from '../../components/itemview'
import Seo from '../../components/seo'

export const query = graphql`
fragment squareImage on File {
  publicURL
}
query {
  slide1: file(relativePath: { eq: "izdeliya/rumka/1.jpg" }) {
    ...squareImage
  }
  slide2: file(relativePath: { eq: "izdeliya/rumka/2.jpg" }) {
    ...squareImage
  }
  slide3: file(relativePath: { eq: "izdeliya/rumka/3.jpg" }) {
    ...squareImage
  }
  slide11: file(relativePath: { eq: "izdeliya/rumka/11.jpg" }) {
    ...squareImage
  }
  slide22: file(relativePath: { eq: "izdeliya/rumka/22.jpg" }) {
    ...squareImage
  }
  slide33: file(relativePath: { eq: "izdeliya/rumka/33.jpg" }) {
    ...squareImage
  }
  slide1mob: file(relativePath: { eq: "izdeliya/rumka/1mob.jpg" }) {
    ...squareImage
  }
  slide2mob: file(relativePath: { eq: "izdeliya/rumka/2mob.jpg" }) {
    ...squareImage
  }
  slide3mob: file(relativePath: { eq: "izdeliya/rumka/3mob.jpg" }) {
    ...squareImage
  }
  slide11mob: file(relativePath: { eq: "izdeliya/rumka/11mob.jpg" }) {
    ...squareImage
  }
  slide22mob: file(relativePath: { eq: "izdeliya/rumka/22mob.jpg" }) {
    ...squareImage
  }
  slide33mob: file(relativePath: { eq: "izdeliya/rumka/33mob.jpg" }) {
    ...squareImage
  }
  slide4mob: file(relativePath: { eq: "izdeliya/rumka/4mob.jpg" }) {
    ...squareImage
  }
  slide5mob: file(relativePath: { eq: "izdeliya/rumka/5mob.jpg" }) {
    ...squareImage
  }
  slide6mob: file(relativePath: { eq: "izdeliya/rumka/6mob.jpg" }) {
    ...squareImage
  }
  slide4: file(relativePath: { eq: "izdeliya/rumka/4.jpg" }) {
    ...squareImage
  }
  slide5: file(relativePath: { eq: "izdeliya/rumka/5.jpg" }) {
    ...squareImage
  }
  slide6: file(relativePath: { eq: "izdeliya/rumka/6.jpg" }) {
    ...squareImage
  }
  slide44mob: file(relativePath: { eq: "izdeliya/rumka/44mob.jpg" }) {
    ...squareImage
  }
  slide55mob: file(relativePath: { eq: "izdeliya/rumka/55mob.jpg" }) {
    ...squareImage
  }
  slide44: file(relativePath: { eq: "izdeliya/rumka/44.jpg" }) {
    ...squareImage
  }
  slide55: file(relativePath: { eq: "izdeliya/rumka/55.jpg" }) {
    ...squareImage
  }
  video1: file(
    relativePath: {eq: "rumka1.mp4"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
  poster1: file(
    relativePath: {eq: "poster-rumka1.png"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
  video2: file(
    relativePath: {eq: "rumka2.mp4"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
  poster2: file(
    relativePath: {eq: "poster-rumka2.png"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
}
`
/* Make slider using only one group of childrens by childrens prop ex.:group and make urrent group a state changeble by event for outside toggle */

const RumkaPage = ({ data }) => {
  return (<>
    <Seo
      title={`Рюмка под водку`}
    />
    <ItemView
      data={data}
      header={<>Рюмка под<br /> водку</>}
      maintext={<>Хрусталь в сочетании с витиеватыми узорами барокко. Рюмки выпускаются в ограниченной серии для коллекционирования и эстетического наслаждения самых изысканных ценителей. Таким образом, они становятся не только функциональным прибором на столе, но и украшением или дорогим подарком.
        <br />Предлагается комплектом в коробке из благородного дуба, украшенной фамильной монограммой.
      </>}
      pricetext={{
        slvr: <>
          <div className={`text-primary is-size-6-touch`}>Цена комплекта<br />из&nbsp;<span className={`text-standart`}>двух</span>&nbsp;рюмок</div>
          <div><span className={`is-size-2 is-size-3-touch`}>169000 <span className={`is-size-3 is-size-5-touch`}>руб</span></span></div>
        </>,
        brs: <>
          <div className={`text-primary is-size-6-touch`}>Цена комплекта<br />из&nbsp;<span className={`text-standart`}>двух</span>&nbsp;рюмок</div>
          <div><span className={`is-size-2 is-size-3-touch`}>89000 <span className={`is-size-3 is-size-5-touch`}>руб</span></span></div>
        </>
      }}
    /></>
  )
}

export default RumkaPage
